import { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { submitForm } from '../api/app-api';
import ReCAPTCHA from "react-google-recaptcha";

const OptionSelection = (props) => {
  const { data, setData, setStep, setResultNum, appData, setAssessmentID, setErrorMsg } = props;
  const [errors, setErrors] = useState({
    programOption1: false,
    programOption2: false,
    programOption3: false,
    recaptcha: false,
  })

  const recaptchaRef = useRef();

  const handleSelectOption = (name, value) => {
    let check = {
      programOption1: data.programOption1,
      programOption2: data.programOption2,
      programOption3: data.programOption3
    }

    if (check.programOption1 === value) {
      check.programOption1 = '';
    }

    if (check.programOption2 === value) {
      check.programOption2 = '';
    }

    if (check.programOption3 === value) {
      check.programOption3 = '';
    }

    check = {
      ...check,
      [name]: value
    }

    setData({
      ...data,
      ...check,
    })

    setErrors({
      ...errors,
      [name]: false,
    })
  }

  const handleSubmit = async() => {
    let newErrors = {
      programOption1: false,
      programOption2: false,
      programOption3: false,
    }

    let isValid = true;

    if (!data.programOption1) {
      newErrors.programOption1 = true;
      isValid = false;
    }

    if (!data.programOption2) {
      newErrors.programOption2 = true;
      isValid = false;
    }

    if (!data.programOption3) {
      newErrors.programOption3 = true;
      isValid = false;
    }

    if (isValid) {
      const token = await recaptchaRef.current.executeAsync();

      const result = await submitForm({...data, ReCaptchaResponse: token});
      if (result.isSuccessful) {
        setResultNum(7);
        setAssessmentID(result.assessmentID);
      } else {
        setResultNum(6);
        setErrorMsg(result.errors)
      }

      setStep('submission');
      window.scrollTo(0, 0);
    }
    else setErrors(newErrors);
  }

  const goToPreviousPage = () => {
    setStep('qualification');
    window.scrollTo(0, 0);
  }

  return (
      <>
          <h2>4. Option Selection</h2>
          <p className="mb-7">
            The BBA Matrix has six required courses and three options to choose from. Please visit the <a className="underline" href="https://www.bcit.ca/programs/bachelor-of-business-administration-bachelor-of-business-administration-full-time-part-time-9975bba/#courses">BBA course page</a> to review the options available. These options allow you to select a specialized option to tailor your learning towards your interests and career goals. <br /><br />
            Please note that we cannot guarantee that students will receive their first choice. We are asking students to rank the options from one to three and the BBA department will notify you of your assigned option.
          </p>
          <div className="mb-6">
              <h3>*First Option Choice:</h3>
              <select className={errors.programOption1 && 'error'} id="programOption1" name="programOption1" onChange={(e)=>{ handleSelectOption('programOption1', e.target.value) }} value={data.programOption1}>
                <option value="">Select an option...</option>
                {
                  appData && appData.programOptions.map((o, i) => {
                    return (
                      <option key={`program option ${i+1}`} value={o.programOptionName}>{ o.programOptionName }</option>
                    )
                  })
                }
              </select>
              { errors.option1 && <p className="error">Required</p>}
          </div>
          <div className="mb-6">
              <h3>*Second Option Choice:</h3>
              <select className={errors.programOption2 && 'error'} id="programOption2" name="programOption2" onChange={(e)=>{ handleSelectOption('programOption2', e.target.value) }} value={data.programOption2}>
                <option value="">Select an option...</option>
                {
                  appData && appData.programOptions.map((o, i) => {
                    return (
                      <option key={`program option ${i+1}`} value={o.programOptionName}>{ o.programOptionName }</option>
                    )
                  })
                }
              </select>
              { errors.option2 && <p className="error">Required</p>}
          </div>
          <div className="mb-10">
              <h3>*Third Option Choice:</h3>
              <select className={errors.programOption3 && 'error'} id="programOption3" name="programOption3" onChange={(e)=>{ handleSelectOption('programOption3', e.target.value) }} value={data.programOption3}>
                <option value="">Select an option...</option>
                {
                  appData && appData.programOptions.map((o, i) => {
                    return (
                      <option key={`program option ${i+1}`} value={o.programOptionName}>{ o.programOptionName }</option>
                    )
                  })
                }
                </select>
                { errors.option3 && <p className="error">Required</p>}
          </div>
          <div className="form-group">
            <h3>Additional Comments (Optional)</h3>
            <textarea value={data.additionalNotes} className="w-full h-full py-[6px] px-[12px] border-[#ccc] border border-solid rounded" rows="2" cols="20" id="txtAdditionalNotes" name="additionalNotes" onChange={(e)=>{ setData({...data, additionalNotes: e.target.value }) }}></textarea>
          </div>
          <hr className="mb-10" />
          <small>This site is protected by reCAPTCHA and the Google 
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </small>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LcsNfApAAAAAMQNuq0HyZ3h3HwexsBjffv-ywZ-"
          />
          <div className='flex justify-between my-10'>
              <Button variant="outlined" onClick={goToPreviousPage}>
                  Previous
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                  Submit Assessment
              </Button>
          </div>
          
      </>
  )
}

export default OptionSelection;