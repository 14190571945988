import Logo from '../imgs/BCITLogo.svg';

const Layout = (props) => {
    const currentYear = new Date().getFullYear();

    return (
        <>
            <div className="bg-[#fdd835] py-80"></div>
            <div className="absolute w-full flex justify-center items-center py-20 top-0 flex-col px-4 lg:px-0">
                <img className='h-32 mb-10' src={Logo} alt="BCIT logo" />
                <div className='font-bold text-3xl text-[#003e6b] mb-14'>School of Business <br className='sm:hidden' />BBA Assessment Form</div>
                <div className='bg-white w-full max-w-[700px] mx-auto py-7 px-5 sm:px-10 text-left' style={{ 'boxShadow': '0 0 100px 0 rgb(51 51 51 / 40%)'}}>
                    {props.children}    
                    <hr className='my-5' />
                    <p className='mb-2 text-center'>
                        © {currentYear} - School of Business BBA Assessment Form | <a href="https://www.bcit.ca/privacy/">Privacy Policy</a> | <a href="mailto:bba@bcit.ca">Contact Support</a>
                    </p>
                </div>
            </div>
        </>
    )
}

export default Layout;