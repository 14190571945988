import axios from 'axios';
import Parser from 'html-react-parser';
import { toast } from 'react-toastify';

const db = async (endpoint, args, type = 'get', contentType = 'application/json') => {
    try {
        const axiosInstance = axios.create();

        const headers = {};

        // user authentication
        // if () {
        headers['Content-Type'] = contentType;
        // headers["Authorization"] = `Bearer ${user.token}`;
        // }

        let result;

        if (type === 'post') {
            result = await axiosInstance.post(`/Form/${endpoint}`, args, {
                headers,
            });
        } else if (type === 'get') {
            result = await axiosInstance.get(`/Form/${endpoint}`, {
                headers,
            });
        }

        if (result?.data) {
            return result.data;
        }
    } catch (err) {
        console.log(err);
        if (err?.response?.data?.message) {
            toast.error(Parser(err.response.data.message));
            return;
        }
    }

    return null;
};

export default db;