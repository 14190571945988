import { useState } from "react";
import Button from '@mui/material/Button';

const Qualification = (props) => {
    const { data, setData, setStep, appData } = props;
    const [errors, setErrors] = useState({
        graduationMonth: false,
        graduationYear: false,
        diplomaID: false,
        diplomaOther: false,
        otherCredentialNotes: false,
    })

    const handleChanges = ( name, value ) => {
        setData({
            ...data,
            [name]: value,
        });

        setErrors({
            errors,
            [name]: false,
        })
    }
    
    const handleValidateInputs = () => {
        const yearPattern = /^[0-9]{4}/;
        let newErrors = {
            graduationMonth: false,
            graduationYear: false,
            diplomaID: false,
            diplomaOther: false,
            otherCredentialNotes: false,
        }
        let isValid = true;

        if (!data.graduationMonth) {
            newErrors.graduationMonth = true;
            isValid = false;
        }

        if (!data.graduationYear || !(data.graduationYear).toString().trim() || !(data.graduationYear).toString().match(yearPattern)) {
            newErrors.graduationYear = true;
            isValid = false;
        }

        if (!data.diplomaID) {
            newErrors.diplomaID = true;
            isValid = false;
        }

        if (data.diplomaID === 20 && !data.diplomaOther) {
            newErrors.diplomaOther = true;
            isValid = false;
        }

        if (data.otherCredential && !data.otherCredentialNotes) {
            newErrors.otherCredentialNotes = true;
            isValid = false;
        }

        setErrors(newErrors)
        if (isValid) {
            setStep('optionSelection');
            window.scrollTo(0, 0);
        }
        
    }

    const goToPreviousPage = () => {
        setStep('personalDetail');
        window.scrollTo(0, 0);
    }

    return (
        <>
            <h2 className="mb-2">3. Qualification</h2>
            <h3>{ !data.completedDiploma && 'Expected' } Month/Year of Graduation:</h3>
            <div className="form-group flex gap-6">
                <div className="flex-1">
                    <select id="drpGraduationMonth" className={ errors.graduationMonth && 'error' } value={data.graduationMonth} onChange={(e) => { handleChanges('graduationMonth', parseInt(e.target.value)) }}>
                      <option value=''>Select a month...</option>
                      <option value="1">Jan</option>
                      <option value="2">Feb</option>
                      <option value="3">Mar</option>
                      <option value="4">Apr</option>
                      <option value="5">May</option>
                      <option value="6">Jun</option>
                      <option value="7">Jul</option>
                      <option value="8">Aug</option>
                      <option value="9">Sep</option>
                      <option value="10">Oct</option>
                      <option value="11">Nov</option>
                      <option value="12">Dec</option>
                    </select>
                    { errors.graduationMonth && <p className="error">Please select the month of graduation</p>}
                </div>
                <div className="flex-1">
                    <input className={ errors.graduationYear && 'error' } type="number" name="graduationYear" value={data.graduationYear} placeholder="YYYY" required onChange={(e) => { handleChanges( e.target.name, parseInt(e.target.value)) }} />
                    { errors.graduationYear && <p className="error">Please provide the year of graduation</p>}
                </div>
            </div>
            <div className="form-group">
                <h3>Specify Diploma:</h3>
                <select className={ errors.diplomaID && 'error' } id="drpCurrentDiploma" name="StudentDiplomaID" value={data.diplomaID} onChange={(e) => { handleChanges('diplomaID', parseInt(e.target.value)) }}>
                    <option value=''>Select a diploma...</option>
                    {
                        appData && appData.diplomas.map((o, i) => {
                            return (
                                <option value={o.diplomaID}>{ o.diplomaName }</option>
                            )
                        })
                    }
                </select>
                {errors.diplomaID && <p className="error">Please select the diploma you graduated from</p>}
                {data.diplomaID === 20 && <input type="text" className={`mt-2 ${errors.diplomaOther ? 'error' : ''}`} value={data.diplomaOther} placeholder="Specify other diploma" onChange={(e) => { handleChanges('diplomaOther', e.target.value) }} />}
                {errors.diplomaOther && <p className="error">Please provide other diploma</p>}
            </div>
            <div className="flex gap-6 mb-2">
                <div className="flex-1">
                    <h3>Overall Grade Percentage:</h3>
                    <input value={data.coreGPA} type="number" name="coreGPA" placeholder="75" required onChange={(e) => { handleChanges( e.target.name, parseInt(e.target.value) ) }} />
                </div>
                <div className="flex-1">
                    <h3>Business Communications 2:</h3>
                    <input value={data.course1GPA} type="number" name="course1GPA" placeholder="75" required onChange={(e) => { handleChanges( e.target.name, parseInt(e.target.value) ) }} />
                </div>
            </div>
            <div className="flex gap-6 mb-2">
                <div className="flex-1">
                    <h3>Accounting 2:</h3>
                    <input value={data.course2GPA} type="number" name="course2GPA" placeholder="75" required onChange={(e) => { handleChanges( e.target.name, parseInt(e.target.value) ) }} />
                </div>
                <div className="flex-1">
                    <h3>Essentials of Marketing:</h3>
                    <input value={data.course3GPA} type="number" name="course3GPA" placeholder="75" required onChange={(e) => { handleChanges( e.target.name, parseInt(e.target.value) ) }} />
                </div>
            </div>
            <div className="flex gap-6 form-group">
                <div className="flex-1">
                    <h3>Microeconomics:</h3>
                    <input value={data.course4GPA} type="number" name="course4GPA" placeholder="75" required onChange={(e) => { handleChanges( e.target.name, parseInt(e.target.value) ) }} />
                </div>
                <div className="flex-1">
                    <h3>Statistics:</h3>
                    <input value={data.course5GPA} type="number" name="course5GPA" placeholder="75" required onChange={(e) => { handleChanges( e.target.name, parseInt(e.target.value) ) }} />
                </div>
            </div>
            <div className="form-group">
                <h3 className="mb-5">Do you have any credits completed within the last 10 years from other post-secondary institute(s)?</h3>
                <div className="radio-container">
                    <input type="radio" value={true} checked={data.otherCredential} onChange={() => { handleChanges('otherCredential', true) }} /> <label>Yes</label>
                </div>
                <div className="radio-container">
                    <input type="radio" value={false} checked={data.otherCredential === false} onChange={() => { handleChanges('otherCredential', false) }} /> <label>No</label>  
                </div>    
            </div>

            {
                data.otherCredential && (
                    <>
                        <div className="form-group">
                            <h3 className="mb-2">Credential Detail</h3>
                            <textarea className={`w-full min-h-[100px] py-[6px] px-[12px] border-[#ccc] border border-solid rounded ${errors.otherCredentialNotes && 'error'}`} rows="2" cols="20" id="txtAdditionalNotes" name="AdditionalNotes" value={data.otherCredentialNotes} placeholder="Please list post-secondary institute(s) attended, year(s), and the approximate number of credits that you completed." onChange={(e) => { handleChanges( 'otherCredentialNotes', e.target.value ) }}></textarea>  
                            { errors.otherCredentialNotes && <p className="error">Please provide the details of the other credential</p>}
                        </div>
                    </>
                )
            }

            <div className='flex justify-between mb-10'>
                <Button variant="outlined" onClick={goToPreviousPage}>
                    Previous
                </Button>
                <Button variant="contained" onClick={handleValidateInputs}>
                    Next
                </Button>
            </div>
        </>
    )
}

export default Qualification;