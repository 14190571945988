import { useState } from "react";

const Password = (props) => {
    const { setStep } = props;
    const [pwd, setPwd] = useState('');

    const handleChange = (value) => {
        setPwd(value);
    }

    const handleSubmit = () => {
        if(pwd === 'bunker') {
            localStorage.setItem('bunker', true);
            setStep('eligibility');
        }
    }

    return (
        <form className="flex justify-center items-center py-10" onSubmit={handleSubmit}>
            <div>
                <input type="password" className="border border-[#003e6b] rounded-md text-lg px-2 py-1 mb-2" placeholder="Enter Password" onChange={(e)=>{ handleChange(e.target.value) }} /><br />
                <button className="w-full bg-[#1976d2] rounded text-white py-2 " onClick={handleSubmit}>Submit</button>
            </div>
        </form>
    )
}

export default Password;