import Button from '@mui/material/Button';

const Result = (props) => {
    const {num, setStep} = props;

    return (
        <>
            <div className="my-20 text-center">
                {
                    num === 1 ? (
                            <>
                                <h1>You have submitted this form already.</h1>
                                <p>
                                    Please email <a href="mailto:bba@bcit.ca">bba@bcit.ca</a> with your name and student ID and we will get back to you within three business days.
                                </p>
                            </>
                        ) :
                        num === 2 ? (
                                <>
                                    <h1>The BBA form is for internal BCIT students.</h1>
                                    <p>
                                        Please email <a href="mailto:bba@bcit.ca">bba@bcit.ca</a> with any relevant post-secondary and/or work experience. The Department will then
                                        direct you accordingly.
                                    </p>
                                </>
                            ) :
                            num === 3 ? (
                                    <>
                                        <h1>Please contact the Department directly as you may not be eligible to currently apply for the BBA.</h1>
                                        <p>
                                            Please contact the Department at <a href="mailto:bba@bcit.ca">bba@bcit.ca</a> with your name and student ID. We will assess your situation
                                            on an individual basis to determine the appropriate next steps.
                                        </p>
                                    </>
                                ) :
                                num === 4 ? (
                                        <>
                                            <h1>A diploma must be completed.</h1>
                                            <p>
                                                A diploma must be completed and awarded for a student to be assessed for admission into the Part-Time BBA. Please complete your
                                                assessment once you meet these criteria.
                                            </p>
                                        </>
                                    ) :
                                    num === 5 && (
                                        <>
                                            <h1>You’re eligible to be assessed.</h1>
                                            <Button variant="contained" onClick={() => {
                                                setStep('personalDetail')
                                            }}>
                                                Fill Out Assessment Form
                                            </Button>
                                        </>
                                    )
                }
            </div>
        </>
    )
}

export default Result;