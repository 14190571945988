import { useState } from 'react';
import Button from '@mui/material/Button';

const PersonalDetail = (props) => {
    const { data, setData, appData, setStep } = props;
    const [errors, setErrors] = useState({
        studentID: false,
        firstName: false,
        lastName: false,
        email: false,
        term: false,
    });

    const handleChanges = (name, value) => {
        setData({
            ...data,
            [name]: value,
        });

        setErrors({
            ...errors,
            [name]: false,
        })
    };
    
    const handleValidateInputs = () => {
        const bcitIdPattern = /^[A|a][0-9]{8}$/;
        const emailPattern = /[^\s@]+@[^\s@]+\.[^\s@]+/;

        let isValid = true;
        let newErrors = {
            studentID: false,
            firstName: false,
            lastName: false,
            email: false,
            term: false,
            exchange: false,
        }

        if ((data.studentID && (!data.studentID.trim() || !data.studentID.match(bcitIdPattern))) || !data.studentID) {
            console.log('he?')
            newErrors.studentID = true;
            isValid = false;
        }

        if ((data.firstName && !data.firstName.trim()) || !data.firstName) {
            newErrors.firstName = true;
            isValid = false;
        }

        if ((data.lastName && !data.lastName.trim()) || !data.lastName) {
            newErrors.lastName = true;
            isValid = false;
        }

        if ((data.email && (!data.email.trim() || !data.email.match(emailPattern))) || !data.email) {
            newErrors.email = true;
            isValid = false;
        }

        if (!data.term) {
            newErrors.term = true;
            isValid = false;
        }

        if (data.interestedInExchangeProgram === null) {
            newErrors.exchange = true;
            isValid = false;
        }

        setErrors(newErrors);

        if (isValid) {
            setStep('qualification');
            window.scrollTo(0, 0);
        }
    }

    return (
        <>
            <h2 className="mb-2">2. Personal Details</h2>
            <p className="mb-6">
                Please fill out the BBA assessment form below. We will be using this form to review information about your academic history and may be contacting you for clarification. <br /><br /> 
                It is <span className="text-red-500">critical</span> that your name, e-mail and BCIT student ID are correct so we can further review your student records in our registration system.
            </p>
            <div className="form-group">
                <h3>BCIT Student ID</h3>
                <input className={errors.studentID && 'error'} type="text" placeholder="i.e. A00123456" value={data.studentID} name="studentID" onChange={(e)=>{ handleChanges(e.target.name, e.target.value) }} required />
                { errors.studentID && <p className="error">Please provide a valid BCIT student ID.</p> }
                <a className="underline" target="_blank" href="https://kb.bcit.ca/student/retrieving-a-lost-or-forgotten-bcit-id-76/">Forgot your BCIT ID?</a>
            </div>
            <div className="form-group flex gap-6">
                <div className="flex-1">
                    <h3>First Name</h3>
                    <input className={errors.firstName && 'error'} type="text" placeholder="Please provide your legal name" value={data.firstName} name="firstName" onChange={(e)=>{ handleChanges(e.target.name, e.target.value) }} required />
                    { errors.firstName && <p className="error">Please provide your legal first name.</p>}
                </div>
                <div className="flex-1">
                    <h3>Last Name</h3>
                    <input className={errors.lastName && 'error'} type="text" placeholder="Please provide your legal name" value={data.lastName} name="lastName" onChange={(e)=>{ handleChanges(e.target.name, e.target.value) }} required />
                    { errors.lastName && <p className="error">Please provide your legal last name.</p>}
                </div>
            </div>
            <div className="form-group">
                <h3>Email</h3>
                <input className={errors.email && 'error'} type="text" placeholder="Please provide your email" value={data.email} name="email" onChange={(e)=>{ handleChanges(e.target.name, e.target.value) }} required />
                { errors.email && <p className="error">Please provide a valid email address.</p>}
            </div>
            <div className="form-group flex gap-6">
                <div className="flex-1">
                    <h3>Program Preference:</h3>
                    <select name="ProgramPreferenceID" value={data.programPreferenceID} disabled>
                        <option value="1">Full-Time (Day)</option>
                        <option value="2">Part-Time (Evening)</option>
                    </select>
                </div>
                <div className="flex-1">
                    <h3>Term Applying For:</h3>
                    <select className={errors.lastName && 'error'} id="drpTerm" name="Term" value={data.term} onChange={(e) => { handleChanges('term', e.target.value) }}>
                        <option value=''>Select a term</option>
                        {
                            
                            data.programPreferenceID === 1 ? (
                                <>
                                    {
                                        appData.fullTimeTerms.map((o, i) => {
                                            return <option key={i} value={o.term}>{ o.termName }</option>
                                        })
                                    }
                                </>
                            ) :
                        
                            data.programPreferenceID === 2 && (
                                <>
                                    {
                                        appData.partTimeTerms.map((o, i) => {
                                            return <option key={i} value={o.term}>{ o.termName }</option>
                                        })
                                    }
                                </>
                            )
                            
                        }
                        
                    </select>
                    { errors.term && <p className="error">Please select a term.</p>}
                </div>
            </div>

            <div className='form-group'>
                <h3 className='mb-5'>
                    Do you have interest in being connected with the School of Business & Media’s Global Relations department about the various outbound exchange programs available to BCIT students?
                </h3>
                <div className="radio-container">
                    <input type="radio" checked={data.interestedInExchangeProgram} onChange={() => { handleChanges('interestedInExchangeProgram', true) }} /> <label>Yes</label>
                </div>
                <div className="radio-container mb-5">
                    <input type="radio" checked={data.interestedInExchangeProgram === false} onChange={() => { handleChanges('interestedInExchangeProgram', false) }} /> <label>No</label>  
                </div> 
                { errors.exchange && <p className="error">Please select an option.</p>}
                <h3 className='mb-5'>Comments: </h3>
                <textarea className={`w-full min-h-[50px] py-[6px] px-[12px] border-[#ccc] border border-solid rounded`} rows="2" id="" name="" value={data.exchangeComment} placeholder="Please provide any additional information you would like to share about your interest in our exchange program." onChange={(e) => { handleChanges('exchangeComment', e.target.value) }}></textarea>  
            </div>

            <ul className="py-5 pl-10 border-l-8 border-[#3981F0] bg-[#E4ECFA] text-[#093477] mb-7 list-disc">
              <li>You can expect to hear from the department in 3-4 weeks.</li>
            </ul>

            <div className='text-right mb-10'>
                <Button variant="contained" onClick={handleValidateInputs}>
                    Next
                </Button>
            </div>
        </>
    );
};

export default PersonalDetail;