import {useState, useEffect} from 'react';
import './App.css';
import Layout from './comps/layout';
import {getAppData} from './api/app-api';

//pages
import * as Sentry from '@sentry/browser';
import Password from './comps/password';
import Eligibility from './comps/eligibility';
import Result from './comps/result';
import PersonalDetail from './comps/personalDetail';
import Qualification from './comps/qualification';
import OptionSelection from './comps/optionSelection';
import Submission from './comps/submission';


const PRODUCTION = 'production';
if (process.env.NODE_ENV === PRODUCTION) {

    Sentry.init({
        dsn: 'https://aad26e2d7ebb49809b0eb2c446db5b20@o972955.ingest.us.sentry.io/4504475233288192',
        release: "1.0",
        environment: window.location.hostname.indexOf("test") > 0 ? "test" : "production"
    });

}

function App() {
    const [step, setStep] = useState('eligibility');
    const [resultNum, setResultNum] = useState();
    const [assessmentID, setAssessmentID] = useState();
    const [errorMsg, setErrorMsg] = useState([]);
    const [data, setData] = useState({
        hasApplied: null,
        hasID: null,
        currentDiploma: null,
        studentID: null,
        firstName: null,
        lastName: null,
        email: null,
        programPreferenceID: null,
        term: null,
        isStudent: null,
        isGraduated: null,
        graduationMonth: null,
        graduationYear: null,
        diplomaID: null,
        diplomaOther: null,
        coreGPA: null,
        course1GPA: null,
        course2GPA: null,
        course3GPA: null,
        course4GPA: null,
        course5GPA: null,
        programOption1: null,
        programOption2: null,
        programOption3: null,
        otherCredential: null,
        otherCredentialNotes: null,
        additionalNotes: null,
        interestedInExchangeProgram: null,
        exchangeComment: null,
    })
    const [appData, setAppData] = useState();

    useEffect(() => {
        if (window.location.hostname.indexOf("test") > 0) setStep('password');

        const fetchAppData = async () => {
            const appData = await getAppData();
            setAppData(appData);
        }

        fetchAppData();
    }, [])

    return (
        <div className="App relative">
            <Layout>
                {
                    step === 'password' ? <Password setStep={setStep}/> : step === 'eligibility' ?
                        <Eligibility setStep={setStep} setResultNum={setResultNum} setData={setData} data={data}/> : step === 'result' ?
                            <Result setStep={setStep} num={resultNum}/> : step === 'personalDetail' ?
                                <PersonalDetail data={data} setData={setData} setStep={setStep} appData={appData}/> : step === 'qualification' ?
                                    <Qualification data={data} setData={setData} setStep={setStep} appData={appData}/> : step === 'optionSelection' ?
                                        <OptionSelection data={data} setData={setData} setStep={setStep} setResultNum={setResultNum} appData={appData}
                                                         setAssessmentID={setAssessmentID} setErrorMsg={setErrorMsg}/> : step === 'submission' &&
                                        <Submission num={resultNum} assessmentID={assessmentID} errorMsg={errorMsg}/>
                }
            </Layout>
        </div>
    );
}

export default App;
