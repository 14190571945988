import db from './createTimetablesWebApiHttpClient';

export const getAppData = async() => {
    try {
        const data  = await db(`GetAppData`);
        return data;
    }
    catch (error) {
        return error;
    }
    
}

export const submitForm = async(formData) => {
    try {
        const data = db(`SubmitAssessment`, formData, 'post');
        return data;
    } catch (error) {
        return error;
    }
}



