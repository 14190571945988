import { useState } from "react";
import Button from '@mui/material/Button';

const Eligibility = (props) => {
    const { setStep, setResultNum, setData, data, appData } = props;

    const handleChanges = (name, value, resultNum = null) => {
        let newData;

        if (name === 'hasApplied') {
            newData = {
                [name]: value,
                hasID: null,
                isStudent: null,
                currentDiploma: null,
                isGraduated: false,
                programPreferenceID: null,
            }
        } else if (name === 'hasID') {
            newData = {
                [name]: value,
                currentDiploma: null,
                isGraduated: null,
                programPreferenceID: null,
            }
        } else if (name === 'isStudent') {
            newData = {
                [name]: value,
                currentDiploma: null,
                isGraduated: null,
                programPreferenceID: null,
            }
        } else if (name === 'isGraduated') {
            newData = {
                [name]: value,
                programPreferenceID: null,
            }
        } else {
            newData = {
                ...data,
                [name]: value,
            }
        }
        
        setData({
            ...data,
            ...newData
        })
        
        if (resultNum) setResultNum(resultNum);
    }

    const checkBtnStatus = () => {
        return !(data.hasApplied || (!data.hasApplied && data.hasID === false) || (!data.hasApplied && data.hasID && data.isStudent === false && data.isGraduated === false) || (!data.hasApplied && data.hasID && data.isStudent && data.programPreferenceID) || (!data.hasApplied && data.hasID && data.isStudent === false && data.isGraduated && data.programPreferenceID) );
    }

    const goToNextPage = () => {
        setStep('result');
        window.scrollTo(0, 0)
    }

    return (
        <>
            <h2 className="mb-2">1. Eligibility</h2>
            <p className="mb-6">Before start filling out the assessment form, we will check your eligibility for BBA program.</p>

            <div className="radio-group">
                <h3 className="mb-5">Have you previously filled out this online assessment?</h3>
                <div className="radio-container">
                    <input type="radio" value={true} checked={data.hasApplied} onChange={()=>{ handleChanges('hasApplied', true, 1) }} /> <label>Yes</label>
                </div>
                <div className="radio-container">
                    <input type="radio" value={false} checked={data.hasApplied === false} onChange={() => { handleChanges('hasApplied', false) }} /> <label>No</label>  
                </div>    
            </div>

            {
                data.hasApplied === false && (
                    <div className="radio-group">
                        <h3 className="mb-5">Do you have a BCIT student ID?</h3>
                        <div className="radio-container">
                            <input type="radio" value={true} checked={data.hasID} onChange={()=>{ handleChanges('hasID', true) }} /> <label>Yes</label>
                        </div>
                        <div className="radio-container">
                            <input type="radio" value={false} checked={data.hasID === false} onChange={() => { handleChanges('hasID', false, 2) }} /> <label>No</label>  
                        </div>    
                    </div>
                )
            }

            {
                data.hasApplied === false && data.hasID && (
                    <div className="radio-group">
                        <h3 className="mb-5">Are you a current BCIT diploma student?</h3>
                        <div className="radio-container">
                            <input type="radio" value={true} checked={data.isStudent} onChange={()=>{ handleChanges('isStudent', true) }} /> <label>Yes</label>
                        </div>
                        <div className="radio-container">
                            <input type="radio" value={false} checked={data.isStudent === false} onChange={() => { handleChanges('isStudent', false) }} /> <label>No</label>  
                        </div>    
                    </div>
                )
            }

            {
                data.hasApplied === false && data.hasID && data.isStudent === false && (
                    <div className="radio-group">
                        <h3 className="mb-5">Have you completed your BCIT diploma?</h3>
                        <div className="radio-container">
                            <input type="radio" value={true} checked={data.isGraduated} onChange={()=>{ handleChanges('isGraduated', true) }} /> <label>Yes</label>
                        </div>
                        <div className="radio-container">
                            <input type="radio" value={false} checked={data.isGraduated === false} onChange={() => { handleChanges('isGraduated', false, 3) }} /> <label>No</label>  
                        </div>    
                    </div>
                )
            }

            {
                (data.hasApplied === false && data.hasID && data.isStudent || data.hasApplied === false && data.hasID && !data.isStudent && data.isGraduated) && (
                    <div className="radio-group">
                        <h3 className="mb-5">What is your program preference?</h3>
                        <div className="radio-container">
                            <input type="radio" value={2} checked={data.programPreferenceID === 2} onChange={()=>{ handleChanges('programPreferenceID', 2, data.isGraduated ? 5 : 4) }} /> <label>Part-Time</label>
                        </div>
                        <div className="radio-container">
                            <input type="radio" value={1} checked={data.programPreferenceID === 1} onChange={() => { handleChanges('programPreferenceID', 1, 5) }} /> <label>Full-Time</label>  
                        </div>    
                    </div>
                )
            }

            <Button variant="contained" disabled={checkBtnStatus()} onClick={goToNextPage}>
                Check Eligibility
            </Button>
        </>
    )
}

export default Eligibility;